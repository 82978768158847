import axios from "axios";
import classNames from "classnames";
import { DateTime } from "luxon";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

interface TodaysMatchProps {
  todaysMatch: {
    id: number;
    closingDate: string;
    homeTeam: {
      name: string;
      photo: {
        data: Buffer;
      };
    };
    awayTeam: {
      name: string;
      photo: {
        data: Buffer;
      };
    };
    homeScore: null | number;
    awayScore: null | number;
  };
  prediction: {
    homeScore: number;
    awayScore: number;
  } | null;
}

const TodaysMatch: React.FC<TodaysMatchProps> = ({
  todaysMatch,
  prediction,
}) => {
  const homeRef = useRef<HTMLSelectElement>(null);
  const awayRef = useRef<HTMLSelectElement>(null);
  const { username } = useParams();
  const { search } = useLocation();
  const [closingAt, setClosingAt] = useState("");
  const [isClosed, setIsClosed] = useState(false);
  const navigate = useNavigate();
  const isSent = new URLSearchParams(search).get("sent");

  useEffect(() => {
    const closingAt = () => {
      if (isClosed) return;

      const now = DateTime.now().setZone("UTC+3");
      const todaysMatchDate = DateTime.fromFormat(
        todaysMatch.closingDate,
        "dd-MM-yy HH:mm"
      );

      if (now > todaysMatchDate) {
        setIsClosed(true);

        return;
      }

      const { days, hours, minutes, seconds } = todaysMatchDate
        .diff(now, ["days", "hours", "minute", "seconds"])
        .toObject();

      setClosingAt(
        `${Math.floor(days!)} gün ${Math.floor(hours!)} saat ${Math.floor(
          minutes!
        )} dakika ${Math.floor(
          seconds!
        )} saniye sonra karşılaşma başlayacaktır.`
      );
    };

    closingAt();

    const interval = setInterval(closingAt, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [todaysMatch.closingDate, isClosed]);

  const arrayBufferToBase64 = (buffer: Buffer) => {
    let binary = "";

    const bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b: any) => (binary += String.fromCharCode(b)));

    return window.btoa(binary);
  };

  const submitPrediction = async (e: FormEvent) => {
    e.preventDefault();

    if (isClosed || prediction || todaysMatch.homeScore) return;

    const homeScore = homeRef.current?.value;
    const awayScore = awayRef.current?.value;

    if (!homeScore || !awayScore) return;

    const {
      data: { ok },
    } = await axios.post(
      "https://extrapanel.herokuapp.com/api/predictMatch",
      JSON.stringify({
        homeScore,
        awayScore,
        clientUsername: username,
        matchId: todaysMatch.id,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (ok) {
      navigate("?sent=true");
      window.location.reload();

      return;
    }
  };

  return (
    <div
      className="w-full px-2 py-4 mb-3"
      style={{
        background: "linear-gradient(45deg, #050505e0, #3c3c3ce0)",
        borderRadius: "25px",
      }}
    >
      <header className="mb-4 text-center text-white flex flex-row items-center gap-2 justify-center">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            viewBox="0 0 16 20"
          >
            <path d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"></path>
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"></path>
            <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z"></path>
          </svg>
        </div>
        <h1 className="text-3xl font-bold pb-1">Günün Karşılaşması</h1>
      </header>

      <hr />

      <form
        className="flex flex-col items-center text-white mt-4"
        onSubmit={submitPrediction}
      >
        <div className="flex flex-row items-center gap-2 lg:gap-8 mb-6">
          <div className="flex flex-col items-center gap-2 w-48">
            <img
              src={(() => {
                const base64Flag = "data:image/jpeg;base64,";
                const imageStr = arrayBufferToBase64(
                  todaysMatch.homeTeam.photo.data
                );

                return base64Flag + imageStr;
              })()}
              alt={todaysMatch.homeTeam.name}
              style={{
                height: "64px",
                width: "64px",
              }}
            />
            <h2 className="font-bold text-xl text-center">
              {todaysMatch.homeTeam.name}
            </h2>
            {todaysMatch.homeScore ? (
              <span className="text-4xl">{todaysMatch.homeScore}</span>
            ) : prediction ? (
              <span className="text-4xl">{prediction.homeScore}</span>
            ) : (
              <select
                style={{
                  color: "#A68243",
                  borderRadius: "8px",
                  backgroundColor: "#050505",
                  fontSize: "32px",
                }}
                ref={homeRef}
              >
                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
              </select>
            )}
          </div>
          <div className="font-bold text-3xl">-</div>
          <div className="flex flex-col items-center gap-2 w-48">
            <img
              src={(() => {
                const base64Flag = "data:image/jpeg;base64,";
                const imageStr = arrayBufferToBase64(
                  todaysMatch.awayTeam.photo.data
                );

                return base64Flag + imageStr;
              })()}
              alt={todaysMatch.awayTeam.name}
              style={{
                height: "64px",
                width: "64px",
              }}
            />
            <h2 className="font-bold text-xl text-center">
              {todaysMatch.awayTeam.name}
            </h2>
            {todaysMatch.awayScore ? (
              <span className="text-4xl">{todaysMatch.awayScore}</span>
            ) : prediction ? (
              <span className="text-4xl">{prediction.awayScore}</span>
            ) : (
              <select
                style={{
                  color: "#A68243",
                  borderRadius: "8px",
                  backgroundColor: "#050505",
                  fontSize: "32px",
                }}
                ref={awayRef}
              >
                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
              </select>
            )}
          </div>
        </div>
        <button
          type="submit"
          className={classNames(
            "text-white font-bold w-3/4",
            isClosed || prediction ? "cursor-not-allowed" : "cursor-pointer"
          )}
          style={{
            background:
              prediction && isSent
                ? "#04D976"
                : "linear-gradient(45deg, #bb1104, #440400)",
            border:
              prediction && isSent ? "1px solid #04D976" : "1px solid #b41004",
            padding: ".375rem .75rem",
            borderRadius: ".25rem",
            transition:
              "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
          }}
        >
          {isClosed || todaysMatch.homeScore
            ? "KATILIM SONA ERMİŞTİR"
            : prediction
            ? isSent
              ? "Tahmininiz başarıyla alınmıştır"
              : "Bu Karşılaşmaya Tahminde Bulunmuşsunuz"
            : "Tahmin Gönder"}
        </button>
        {!isClosed && (
          <div className="mt-2 text-center w-3/4 lg:w-auto">{closingAt}</div>
        )}
      </form>
    </div>
  );
};

export default TodaysMatch;
