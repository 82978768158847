import React from "react";
import ReactDOM from "react-dom/client";
import App from "./routes/App";
import Rules from "./routes/Rules";
import Bet from "./routes/Bet";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/rules",
    element: <Rules />,
  },
  {
    path: "/:username",
    element: <Bet />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
