import React from "react";
import Form from "../components/InputForm";

const App: React.FC = () => {
  return (
    <div className="h-full w-full flex">
      <div
        style={{
          maxWidth: "1320px",
        }}
        className="m-auto w-full flex"
      >
        <div
          className="m-auto text-white p-4 w-full md:w-58"
          style={{
            background: "linear-gradient(45deg, #050505e0, #3c3c3ce0)",
            borderRadius: "25px",
            border: "1px solid #333333",
          }}
        >
          <header className="mb-4">
            <img src="/text.png" alt="text" />
          </header>

          <Form />

          <footer className="w-full flex">
            <img
              src="/footer.png"
              alt="logo"
              className="m-auto mt-2"
              style={{
                width: "85%",
              }}
            />
          </footer>
        </div>
      </div>
    </div>
  );
};

export default App;
