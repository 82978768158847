import React from "react";
import { useNavigate } from "react-router-dom";

const Rules: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="h-full w-full flex">
      <div
        style={{
          maxWidth: "1320px",
        }}
        className="m-auto w-full flex"
      >
        <div
          className="m-auto text-white p-4 w-full md:w-58"
          style={{
            background: "linear-gradient(45deg, #050505e0, #3c3c3ce0)",
            borderRadius: "25px",
            border: "1px solid #333333",
          }}
        >
          <nav className="w-full flex items-center justify-start">
            <button
              className=""
              style={{
                border: "1px solid #b41004",
                background: "linear-gradient(45deg, #bb1104, #440400)",
                padding: ".25rem .5rem",
                fontSize: ".875rem",
                borderRadius: ".2rem",
                transition:
                  "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
              }}
              onClick={() => navigate("/")}
            >
              Geri Git
            </button>
          </nav>

          <div className="mt-2 text-center flex flex-col items-center gap-2 mb-4">
            <h1 className="underline text-xl font-bold">ÖDÜL HAVUZU</h1>
            <ul className="list-disc text-sm text-start pl-4 gap-2 flex flex-col">
              <li>
                ExtraSkor ile her gün belirlenen bir karşılaşmanın skorunu doğru
                tahmin eden tüm üyelerimiz 250 TL FreeBet kazanıyor!
              </li>
              <li>
                FreeBet, kat sayı olarak çevrim şartı olmayan, gerekli kupon
                oranından oynanması ile ana kasanıza geçen bir bonus türüdür.
                Bahsinizin sonlanıp kazanması ile sadece kârınız hesabınıza
                geçer. Örneğin 250 TL FreeBet'iniz var ve 2.00 orandan bir bahis
                kuponu yaptınız. 250X2.00 = 500 TL - 250 TL FreeBet = 250 TL ana
                kasanıza geçer.
              </li>
              <li>
                FreeBet'inizi sadece Futbol, Basketbol, Voleybol ve Tenis
                alanlarında alacağınız tekli bahislerde kullanabilirsiniz.
                Minimum 1.80 veya üzeri orandan bir bahis seçerek FreeBet/Bedava
                Bahis kutucuğunu aktif hale getirdikten sonra bahsinizi
                alabilirsiniz. Kazancınız hesabınıza çevrim şartsız olarak
                eklenecektir. Daha sonrasında dilediğiniz gibi spor veya casino
                fark etmeksizin bahis alımı yapabilirsiniz. ExtraSkor'dan
                kazandığınız FreeBet ile çekim yapabileceğiniz minimum tutar 500
                TL, maksimum tutar ise 1000 TL'dir.
              </li>
              <li>
                Ödüller her gün belirlenen karşılaşmanın sona ermesinden sonra
                24 saat içerisinde üye hesaplarına otomatik olarak eklenecektir.
                Üyelerimizin herhangi bir talepte bulunmaları gerekmemektedir.
              </li>
              <li>
                Hesabınızda bakiye veya açık kupon olması durumunda sistem
                tarafından ekleme yapılamamaktadır. Bu kural nedeniyle hesabına
                ödülü eklenmeyen üyeler, ilgili maç bittikten sonraki 24 saat
                içerisinde, bakiyeleri ve açık bahisleri bulunmadığı durumda,
                Canlı Destek hattımız üzerinden taleplerini iletebilirler.
              </li>
            </ul>
          </div>

          <hr />

          <div className="mt-4 text-center flex flex-col items-center gap-2 mb-4">
            <h1 className="underline text-xl font-bold">KURALLAR</h1>
            <ul className="list-disc text-sm text-start pl-4 gap-2 flex flex-col">
              <li>
                Her gün 00.00'da günün karşılaşması yenilenir ve yeni ödül günü
                başlar.
              </li>
              <li>
                ExtraSkor'a katılabilmeniz için her yeni ödül gününde, günün
                karşılaşmasının başlama saatine kadar tek seferde minimum 100 TL
                yatırım işlemi gerçekleştirmiş olmanız gerekmektedir.
              </li>
              <li>
                Tahminlerinizi karşılaşmanın başlama saatine kadar iletmeniz
                gerekmektedir.
              </li>
              <li>Her üyemizin bir adet tahmin hakkı bulunmaktadır.</li>
              <li>
                Tahminde bulunurken kullanıcı adınızı hatalı girmeniz durumunda
                tahminleriniz geçersiz sayılmaktadır.
              </li>
              <li>
                Bir üyenin birden fazla tahminde bulunduğu veya farklı
                hesaplarla etkinliğe katıldığı tespit edilirse tüm kazançları
                geçersiz sayılacaktır.
              </li>
              <li>
                FreeBet, cashout ve iadeli bahisler için geçerli değildir.
              </li>
              <li>
                Etkinlik sonuçlandırması 90 dakikalık normal süre ve hakemin ek
                olarak vereceği 90+ uzatma süreleri sonundaki skora göre
                yapılmaktadır.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rules;
