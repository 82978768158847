import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const Form: React.FC = () => {
  const usernameRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  return (
    <form className="px-2 flex flex-col gap-2">
      <label className="flex flex-col items-center gap-1 w-full">
        <span className="font-bold">Kullanıcı Adınız</span>
        <input
          type="text"
          ref={usernameRef}
          className="w-full"
          style={{
            padding: ".375rem .75rem",
            color: "#212529",
            fontSize: "1rem",
            lineHeight: "1.5",
            border: "1px solid #ced4da",
            borderRadius: ".25rem",
          }}
          required
        />
      </label>
      <button
        type="submit"
        className="w-full font-bold"
        style={{
          border: "1px solid #b41004",
          background: "linear-gradient(45deg, #bb1104, #440400)",
          borderRadius: ".25rem",
          padding: ".375rem .75rem",
          transition:
            "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
        }}
        onClick={() => {
          const username = usernameRef.current?.value;
          if (!username) return;

          navigate(`/${username}`);
        }}
      >
        Tahminde Bulun / Tahminini Gör
      </button>
      <button
        type="button"
        className="w-full font-bold"
        style={{
          border: "1px solid #b41004",
          background: "linear-gradient(45deg, #bb1104, #440400)",
          borderRadius: ".25rem",
          padding: ".375rem .75rem",
          transition:
            "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
        }}
        onClick={() => navigate("/rules")}
      >
        Katılım Şartları
      </button>
    </form>
  );
};

export default Form;
