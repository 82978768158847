import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NextMatches from "src/components/NextMatches";
import TodaysMatch from "src/components/TodaysMatch";

const Bet: React.FC = () => {
  const [todaysMatch, setTodaysMatch] = useState(null);
  const [prediction, setPrediction] = useState(null);
  const { username } = useParams();
  const [nextMatches, setNextMatches] = useState([]);

  useEffect(() => {
    const main = async () => {
      const { data } = await axios.get(
        "https://extrapanel.herokuapp.com/api/getMatches"
      );

      if (data.todaysMatch) {
        const {
          data: { prediction },
        } = await axios.post(
          "https://extrapanel.herokuapp.com/api/checkIsPredicted",
          JSON.stringify({
            matchId: data.todaysMatch.id,
            clientUsername: username,
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        setPrediction(prediction);
      }

      setTodaysMatch(data.todaysMatch);
      setNextMatches(data.nextMatches);
    };

    main();
  }, [username]);

  return (
    <div className="w-full h-full px-2 lg:px-6">
      {todaysMatch && (
        <TodaysMatch todaysMatch={todaysMatch} prediction={prediction} />
      )}
      {nextMatches.length && <NextMatches nextMatches={nextMatches} />}
    </div>
  );
};

export default Bet;
