import React from "react";
import Form from "../components/InputForm";

const App: React.FC = () => {
  return (
    <div className="h-full w-full flex">
      <div
        style={{
          maxWidth: "1320px",
        }}
        className="m-auto w-full flex"
      >
        <div
          className="mx-auto text-white p-4 w-full md:w-58"
          style={{
            background: "rgb(0 0 0",
            borderRadius: "25px",
            border: "1px solid #BA8E47",
          }}
        >
          <header className="mb-4 flex">
            <img src="/text.png" alt="text" className="h-64 xl:h-80 mx-auto" />
          </header>

          <Form />

          <footer className="w-full flex mt-6">
            <img
              src="/footer.png"
              alt="logo"
              className="m-auto mt-2 h-52 xl:h-64"
            />
          </footer>
        </div>
      </div>
    </div>
  );
};

export default App;
