import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";

interface NextMatchProps {
  match: {
    id: number;
    closingDate: string;
    homeTeam: {
      name: string;
      photo: {
        data: Buffer;
      };
    };
    awayTeam: {
      name: string;
      photo: {
        data: Buffer;
      };
    };
  };
}

const NextMatch: React.FC<NextMatchProps> = ({
  match: { homeTeam, awayTeam, closingDate },
}) => {
  const [closingAt, setClosingAt] = useState("");

  useEffect(() => {
    const closingAt = () => {
      const now = DateTime.now().setZone("UTC+3");
      const matchDate = DateTime.fromFormat(closingDate, "dd-MM-yy HH:mm");

      const { days, hours, minutes, seconds } = matchDate
        .diff(now, ["days", "hours", "minute", "seconds"])
        .toObject();

      setClosingAt(
        `${Math.floor(days!)} gün ${Math.floor(hours!)} saat ${Math.floor(
          minutes!
        )} dakika ${Math.floor(
          seconds!
        )} saniye sonra karşılaşma başlayacaktır.`
      );
    };

    closingAt();

    const interval = setInterval(closingAt, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [closingDate]);

  const arrayBufferToBase64 = (buffer: Buffer) => {
    let binary = "";

    const bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b: any) => (binary += String.fromCharCode(b)));

    return window.btoa(binary);
  };

  return (
    <div className="flex items-center justify-center flex-col text-white">
      <div className="flex flex-row items-center gap-2 lg:gap-8 mb-6 w-96 justify-center">
        <div className="flex flex-col items-center gap-2 w-48">
          <h2 className="font-bold text-xl text-center">{homeTeam.name}</h2>
          <img
            src={(() => {
              const base64Flag = "data:image/jpeg;base64,";
              const imageStr = arrayBufferToBase64(homeTeam.photo.data);

              return base64Flag + imageStr;
            })()}
            alt={awayTeam.name}
            style={{
              height: "64px",
              width: "64px",
            }}
          />
        </div>
        <div className="font-bold text-3xl">-</div>
        <div className="flex flex-col items-center gap-2 w-48">
          <h2 className="font-bold text-xl text-center">{awayTeam.name}</h2>
          <img
            src={(() => {
              const base64Flag = "data:image/jpeg;base64,";
              const imageStr = arrayBufferToBase64(awayTeam.photo.data);

              return base64Flag + imageStr;
            })()}
            alt={awayTeam.name}
            style={{
              height: "64px",
              width: "64px",
            }}
          />
        </div>
      </div>
      <div className="mt-2 text-center w-3/4 lg:w-auto">{closingAt}</div>
    </div>
  );
};

export default NextMatch;
